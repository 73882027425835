import { defineStore } from 'pinia';

export const useToastStore = defineStore('toast', {
  state: () => ({
    visible: false,
    message: '',
    type: 'info', // 'error', 'success', 'info', 'warning'
    title: 'Notification',
    showClose: false
  }),
  actions: {
    showToast({ message, type = 'info', title = 'Notification', duration = 4000 }) {
      this.visible = true;
      this.message = message;
      this.type = type;
      this.title = title;

      setTimeout(() => {
        this.clearToast();
      }, duration);
    },
    clearToast() {
      this.visible = false;
      this.message = '';
    }
  }
});
